<template>
  <!-- <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view />
  </div> -->
  <v-app v-if="!loading" :style="cssVars">
    <right-drawer
      ref="rightDrawer"
      :notifications="notRemovedNotifications"
      :updatingNotifications="updatingNotifications"
      :hasNotifications="hasNotifications"
      :popupNotification="popupNotification"
      @remove-all-notifications="removeAllNotifications"
      @mark-all-as-read="markAllAsRead"
      @mark-as-read="markAsReadNotification($event)"
      @remove-notification="removeNotification($event)"
      @update-notifications="updateNotifications"
    />
    <left-drawer ref="leftDrawer" />
    <core-app-bar
      :notifications="notRemovedNotifications"
      :loadingNotifications="loadingNotifications"
      :updatingNotifications="updatingNotifications"
      @toggle-left-nav="$refs.leftDrawer.toggleNav()"
      @toggle-right-nav="$refs.rightDrawer.toggleNav()"
      @open-popup="$refs.leftDrawer.openPopupDialog($event)"
    />
    <core-view @update-notifications="updateNotifications" />
    <core-footer ref="footer" v-show="isLoggedIn" />
    <error-handler :error="error"></error-handler>
  </v-app>
  <p v-else>{{ $t("globals.redirecting") }}</p>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ErrorHandler from "./components/ErrorHandler";
import moment from "moment";
export default {
  components: {
    ErrorHandler,

    LeftDrawer: () =>
      import("./components/VueMaterialDashboard/core/LeftDrawer"),
    RightDrawer: () =>
      import("./components/VueMaterialDashboard/core/RightDrawer"),
    CoreFooter: () =>
      import("./components/VueMaterialDashboard/core/AppFooter"),
    CoreAppBar: () => import("./components/VueMaterialDashboard/core/AppBar"),
    CoreView: () => import("./components/VueMaterialDashboard/core/MainView"),
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    ...mapGetters("common", { notificationsFromSettings: "planNotifications" }),
    ...mapGetters("common", [
      "planAdministrator",
      "logo",
      "settings",
      "vuetifyTheme",
      "favicon",
      "userProfile",
    ]),

    notRemovedNotifications() {
      let addressConfirmed = localStorage.getItem("addressConfirmed");
      let registrationCompleted = localStorage.getItem("registrationCompleted");
      let regCompletedLessThan90daysAgo = registrationCompleted
        ? moment().diff(moment(registrationCompleted), "days") < 90
        : false;
      if (regCompletedLessThan90daysAgo) {
        return this.notifications.filter(
          (n) => n.Status !== "Removed" && n.Type !== "confirm address"
        );
      }
      let filteredNotifications = this.notifications.filter((n) =>
        n.Status !== "Removed" && addressConfirmed
          ? n.Type !== "confirm address"
          : true
      );
      // remove show once notification
      if (filteredNotifications?.length) {
        let oneTimeNotificationIndex = filteredNotifications.findIndex(
          (n) => n.Type === "show-once"
        );
        if (oneTimeNotificationIndex > -1) {
          filteredNotifications.splice(oneTimeNotificationIndex, 1);
        }
      }
      // remove start end date notification if not in range
      if (filteredNotifications?.length) {
        let startEndDateNotificationIndex = filteredNotifications.findIndex(
          (n) => n.Type === "start-end-date"
        );
        if (startEndDateNotificationIndex > -1) {
          let startDateNotification =
            filteredNotifications[startEndDateNotificationIndex];
          let { startDate, endDate, now } = this.getMomentDates(
            startDateNotification.StartDate,
            startDateNotification.EndDate
          );
          let momentIsBetween = now.isBetween(startDate, endDate, "day", "[)");
          if (!momentIsBetween) {
            filteredNotifications.splice(startEndDateNotificationIndex, 1);
          }
          // console.log(
          //   "momentIsBetween1",
          //   momentIsBetween,
          //   now,
          //   startDate,
          //   endDate
          // );
        }
      }
      return filteredNotifications;
    },

    hasNotifications() {
      return this.sentNotifications?.length > 0;
    },

    cssVars() {
      return {
        "--primary": this.$vuetify.theme.themes.light.primary,
        "--secondary": this.$vuetify.theme.themes.light.secondary,
        "--accent": this.$vuetify.theme.themes.light.accent,
        "--accent-lighter": this.$helpers.adjustColorPSBC(
          0.5,
          this.$vuetify.theme.themes.light.accent
        ),
        "--error": this.$vuetify.theme.themes.light.error,
        "--warning": this.$vuetify.theme.themes.light.warning,
        "--warning-lighter": this.$helpers.adjustColorPSBC(
          0.5,
          this.$vuetify.theme.themes.light.warning
        ),
        "--info": this.$vuetify.theme.themes.light.info,
        "--info-lighter": this.$helpers.adjustColorPSBC(
          0.5,
          this.$vuetify.theme.themes.light.info
        ),
        "--success": this.$vuetify.theme.themes.light.success,
      };
    },
  },
  data() {
    return {
      error: null,
      loading: true,
      loadingNotifications: false,
      updatingNotifications: false,
      notifications: [],
      planNotifications: [],
      sentNotifications: [],
      popupNotification: null,
    };
  },
  watch: {
    isLoggedIn: {
      async handler(v) {
        if (v) {
          this.setPlan(this.user.plan);
        } else {
          this.setPlan(undefined);
        }
      },
    },
    userProfile: {
      async handler(v) {
        if (v) {
          await this.loadNotifications();
        }
      },
    },
  },
  methods: {
    ...mapActions("data", ["getNotifications", "updateNotifications"]),
    ...mapActions("common", [
      "setPlan",
      "loadUserProfileData",
      "loadUserImage",
      "loadPortalSettings",
    ]),
    ...mapActions("auth", ["login"]),
    setVuetifyThemeColorsFromSettings() {
      this.$vuetify.theme.themes.light.primary =
        this.vuetifyTheme.Light.Primary;
      this.$vuetify.theme.themes.light.secondary =
        this.vuetifyTheme.Light.Secondary;
      this.$vuetify.theme.themes.light.accent = this.vuetifyTheme.Light.Accent;
      // this.$vuetify.theme.themes.light.error = this.vuetifyTheme.Light.Error;
      this.$vuetify.theme.themes.light.warning =
        this.vuetifyTheme.Light.Warning;
      this.$vuetify.theme.themes.light.info = this.vuetifyTheme.Light.Info;
      this.$vuetify.theme.themes.light.success =
        this.vuetifyTheme.Light.Success;
    },
    setFavicon() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    updateNotifications() {
      this.loadNotifications();
    },

    markAsReadNotification(notification) {
      if (
        notification.Status === "Permanent" ||
        notification.Status === "Popup"
      )
        return;
      const notificationIndex = this.notifications.findIndex(
        (n) => n.Id === notification.Id
      );
      this.notifications[notificationIndex].Status = "Read";
      this.notifications[notificationIndex].DateRead = moment().format(
        "YYYY-MM-DD-HH-mm-ss"
      );
      this.updateNotificatoinsForMember();
    },
    removeNotification(notification) {
      console.log("remove notification", notification);
      const notificationIndex = this.notifications.findIndex(
        (n) => n.Id === notification.Id
      );
      this.notifications[notificationIndex].Status = "Removed";
      this.notifications[notificationIndex].DateRead = moment().format(
        "YYYY-MM-DD-HH-mm-ss"
      );
      this.updateNotificatoinsForMember();
    },
    markAllAsRead() {
      console.log("mark all as read");
      let updated = false;
      this.notifications.forEach((n) => {
        if (n.Status !== "Permanent" && n.Status !== "Popup") {
          updated = true;
          n.Status = "Read";
        }
      });
      if (updated) this.updateNotificatoinsForMember();
    },
    removeAllNotifications() {
      console.log("remove all notifications");
      let updated = false;
      this.notifications.forEach((n) => {
        if (n.Status !== "Permanent" && n.Status !== "Popup") {
          updated = true;
          n.Status = "Removed";
        }
      });
      if (updated) this.updateNotificatoinsForMember();
    },
    async updateNotificatoinsForMember() {
      try {
        this.updatingNotifications = true;

        await this.updateNotifications({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: this.notifications,
        });

        this.updatingNotifications = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        this.updatingNotifications = false;
      }
    },

    getMomentDates(start, end) {
      let startDate = moment(start, "YYYY-MM-DD").startOf("day");
      let endDate = moment(end, "YYYY-MM-DD").startOf("day");
      let now = moment(this.$helpers.formattedDateNow()).startOf("day");
      return { startDate, endDate, now };
    },

    async loadNotifications() {
      let allNotifications = [];
      try {
        this.loadingNotifications = true;
        allNotifications = (
          await this.getNotifications({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loadingNotifications = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        this.loadingNotifications = false;
      } finally {
        this.sentNotifications =
          this.filterNotificationsByMemberGroup(allNotifications);
        this.planNotifications = this.filterNotificationsByMemberGroup(
          this.notificationsFromSettings
        );
        let popupNotifcations = this.planNotifications.filter((n) =>
          n.Category?.toLowerCase().includes("popup")
        );
        // handle show once notification
        if (popupNotifcations?.length) {
          let isOneTimeNotifiactionShown = localStorage.getItem(
            "oneTimeNotificationShown"
          );
          let oneTimeNotificationIndex = popupNotifcations.findIndex(
            (n) => n.Type === "show-once"
          );
          if (oneTimeNotificationIndex > -1) {
            if (!isOneTimeNotifiactionShown) {
              this.popupNotification =
                popupNotifcations[oneTimeNotificationIndex];
            } else {
              popupNotifcations.splice(oneTimeNotificationIndex, 1);
            }
          }
        }
        // handle show start end date notification
        if (popupNotifcations?.length && !this.popupNotification) {
          let startEndDateNotificationIndex = popupNotifcations.findIndex(
            (n) => n.Type === "start-end-date"
          );
          if (startEndDateNotificationIndex > -1) {
            let startDateNotification =
              popupNotifcations[startEndDateNotificationIndex];
            let { startDate, endDate, now } = this.getMomentDates(
              startDateNotification.StartDate,
              startDateNotification.EndDate
            );
            let momentIsBetween = now.isBetween(
              startDate,
              endDate,
              "day",
              "[)"
            );
            console.log(
              "momentIsBetween",
              momentIsBetween,
              now,
              startDate,
              endDate
            );
            if (momentIsBetween) {
              this.popupNotification = startDateNotification;
            } else {
              popupNotifcations.splice(startEndDateNotificationIndex, 1);
            }
          }
        }
        if (!this.popupNotifications?.length && !this.popupNotification)
          this.popupNotification = popupNotifcations[0];
        this.sentNotifications.sort((a, b) => a.DateCreated > b.DateCreated);
        this.notifications = [
          ...this.planNotifications,
          ...this.sentNotifications,
        ];
      }
    },
    filterNotificationsByMemberGroup(notifications) {
      if (!notifications?.length) return [];
      const memberGroup = this.userProfile?.MemberGroup;
      let includesKeyword = "All";
      // if (this.role === "Trustee") includesKeyword = "Trustee";
      // filter out notifications that are not for this member group if they have a MemberTypes property
      return notifications?.filter((i) => {
        if (!i.MemberTypes) return i;

        if (
          i.MemberTypes.includes(memberGroup?.Name) ||
          i.MemberTypes.includes(includesKeyword)
        ) {
          return i;
        }
      });
    },
  },
  async mounted() {
    this.$router.afterEach(() => {
      this.loading = false;
      document.title =
        (this.planAdministrator ? this.planAdministrator + "-" : "") +
        this.$t("globals.app_title");
    });
    this.setVuetifyThemeColorsFromSettings();
    this.setFavicon();
    localStorage.setItem("benefitCardsWarningShown", false);
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
$font-family: "Montserrat", sans-serif;
html {
  overflow-y: auto !important;
}

// #app {
//   font-family: "Barlow", Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// To switch default font for Montserrat
#app {
  .headline,
  [class*="display-"],
  [class*="text-"] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #dde3e9;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.snackbar-link {
  color: black !important;
}

.v-tooltip__content {
  max-width: 400px !important;
  opacity: 1 !important;
  width: 400px !important;
  color: black !important;
  background-color: white !important;
  -webkit-box-shadow: 0 0 10px rgb(146, 146, 146);
  box-shadow: 0 0 10px rgb(146, 146, 146);
}
html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  /* Remove scrollbar space */
  background: #f1f1f1;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: rgb(30, 136, 229);
}
.top-30 {
  padding-top: 30px;
}
.support-exists {
  padding-top: 30px;
  margin-top: 64px !important;
}
.v-btn {
  letter-spacing: 0 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 7px !important;
}
.v-dialog--scrollable {
  overflow: visible !important;
}
.v-messages {
  min-height: auto !important;
}
table thead th[role="columnheader"] {
  color: rgb(30, 136, 229) !important;
}

/*
  Added so that the horizontal bar that follows the browser's scroll
  gets properly hidden when the stepper step collapses
*/
.v-stepper__wrapper[style^="height: 0"] {
  display: none;
}

.required label:before {
  content: "* ";
  color: red;
  font-weight: 900;
}

.v-btn__content .v-icon {
  font-size: 20px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.v-dialog--scrollable > .v-card > div > .v-card__text {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
}
/********************* Added by Randy James */
/* * {
  font-family: "Barlow", sans-serif !important;
} */
.float-right {
  float: right !important;
}
/* .v-main {
  background-image: url(@/assets/login_background_piggybank.jpg) !important;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
} */

::-webkit-scrollbar-thumb {
  background: #b8b8b8 !important;
}
.v-card__title {
  word-break: keep-all !important;
}
.custom-underline {
  text-decoration: none;
  padding-bottom: 0px;
  border-bottom: 1px solid;
  cursor: pointer;
}
.v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}
.vue-html2pdf .pdf-preview button {
  left: auto !important;
  right: -15px !important;
}
</style>
<style>
.v-card__text {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.auto-search {
  font-size: 10px !important;
  line-height: 1 !important;
}
.auto-search1 {
  font-size: 12px !important;
  line-height: 1 !important;
}
.autocomplete-search > div > div {
  height: 25px !important;
  min-height: 25px !important;
}
.benefits > div > .v-subheader {
  font-weight: 700 !important;
  font-size: 0.825rem;
  color: var(--secondary);
}
.benefits {
  z-index: 10 !important;
}
.border-right-none {
  border-right: none !important;
}
.border-left-none {
  border-left: none !important;
}
/* Recomenadtions from Randy */
/* .theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(.vstepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background: rgba(0, 0, 0, 0.5) !important;
}

.v-card__subtitle,
.v-card__text {
  font-weight: 700 !important;
}

.v-btn {
  font-weight: 600 !important;
}

.v-card__title {
  font-weight: 600 !important;
} */
</style>
