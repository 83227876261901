<template>
  <material-card-sba
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    color="accent"
    :widgetIcon="widgetIcon"
    :title="$t('components.vue_material_dashboard.items.hours_bank.title')"
  >
    <v-row no-gutters class="fill-height mx-2" justify="space-around">
      <v-col cols="12" class="mt-12 pt-12">
        <v-col class="pa-0 title1 text-uppercase">{{
          $t("components.vue_material_dashboard.items.hours_bank.balance")
        }}</v-col>

        <v-col class="pa-0 text-h6 font-weight-bold accent--text"
          >{{ parseFloat(hoursBankBalance ?? "0").toFixed(2) || "--" }}
        </v-col>
      </v-col>
      <v-btn color="accent" class="action-btn" :to="'hours-bank'">{{
        $t("globals.view_details")
      }}</v-btn>
      <v-progress-circular
        v-show="loading"
        size="50"
        class="loader"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </material-card-sba>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
export default {
  components: {
    MaterialCardSba,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      loading: false,
      hoursBankBalance: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "role"]),
    ...mapGetters("common", ["userProfile", "hsaLabel", "HSAExpiryDate"]),
    ...mapState("data", ["hsa"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  async mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    await this.loadData();
  },
  methods: {
    ...mapActions("data", ["getHoursBankBalance"]),
    async loadData() {
      try {
        this.loading = true;
        this.hoursBankBalance = (
          await this.getHoursBankBalance({
            memberIdentityId: this.user.sub,
          })
        ).data;
      } catch (error) {
        this.loading = false;
        this.error = error;
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.lh-1 {
  line-height: 1.1rem !important;
}
.title1 {
  font-size: 12px;
  font-weight: 600;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.action-btn {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
</style>
