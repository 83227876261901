/* eslint-disable prettier/prettier */
import { i18n } from "@/plugins/i18n";
import axios from "axios";
import PlanService from "@/services/plan.service";
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service";
import mocksCommon from "./mocksCommon.json";
import demoPreferredProviders from "./demoPreferredProviders.json";
const state = {
  mode: "light",
  plan: undefined,
  settings: undefined,
  language: { language: "en", title: "English" },
  languages: [
    { flag: "us", language: "en", title: "English" },
    { flag: "fr", language: "fr", title: "Français" },
  ],
  navbarOpen: false,
  leftDrawerOpen: false,
  userProfile: undefined,
  useLegacyClaims: false,
  hasOneBenefitCard: false,
  hasBeneficiariesFlag: false,
  portalSettings: {
    // planIdentityId: "1f2b12b1-0600-4cfa-8679-ffef10050e52",
    // isPensionsPortal: true,
    // planAdministrator: "CCWIPP",
    // planAdministratorName: "Canadian Commercial Workers Industry Pension Plan",
    // planAdministratorWebsite: "CCWIPP.ca",
    // logo: "CCWIPP_logo.light.png",
    // favicon: "favicon.png",
    // contactInfo: "",
    theme: {
      Vuetify: {
        Light: {
          Primary: "#d12d36",
          Secondary: "#2a2a2a",
          Accent: "#00bcd4",
          Error: "#f44336",
          Warning: "#ff9800",
          Info: "#3f51b5",
          Success: "#4caf50",
        },
      },
      LeftDrawer: {
        Light: {
          Colors: {
            MenuButton: {
              Background: "#2a2a2a",
              Text: "white",
            },
            Background: "#eeeeee",
            Text: "black",
            MenuIcon: "#575757",
          },
        },
      },
      RightDrawer: {
        Light: {
          Colors: {
            MenuButton: {
              Background: "#2a2a2a",
              Text: "white",
            },
            MenuIcon: {
              Background: "white",
              Text: "#d12d36",
            },
            Background: "white",
            Text: "black",
          },
        },
      },
      AppBar: {
        Light: {
          Colors: {
            Background: "white",
            Text: "#d12d36",
            SystemBar: {
              Background: "#d12d36",
              Text: "white",
            },
            SearchField: {
              Background: "#eb646c",
              Text: "white",
            },
          },
        },
      },
      AppFooter: {
        Light: {
          Colors: {
            Background: "#2a2a2a",
            Text: "white",
          },
        },
      },
      MainView: {
        Light: {
          Colors: {
            Background: "#EAEAEA",
            Text: "grey",
            Spinner: "primary",
          },
        },
      },
    },
  },
  benSource: "",
  pageTitle: "",
  pageHelpTooltip: "",
};

const getters = {
  plan: (state) => {
    if (state.plan) return state.plan;
    const plan = PlanService.getPlan();
    if (localStorage.rememberPlan && plan) {
      try {
        state.plan = plan;
        return state.plan;
      } catch {
        return null;
      }
    }
  },

  portalPlanIdentity: (state) => {
    return state.portalSettings.planIdentityId;
  },
  useLegacyClaims: (state) => {
    return state.useLegacyClaims;
  },
  hasOneBenefitCard: (state) => {
    return state.hasOneBenefitCard;
  },
  memberHasSickPay: (state) => {
    return state.portalSettings.memberHasSickPay;
  },
  enableClaims: (state) => {
    return state.portalSettings.enableClaims;
  },
  allowedMonthsOfClaiming: (state) => {
    return state.portalSettings.allowedMonthsOfClaiming;
  },
  enableClaimsMessage: (state) => {
    return state.portalSettings.enableClaimsMessage;
  },
  planNotifications: (state) => {
    if (state.portalSettings.planNotifications) {
      return state.portalSettings.planNotifications;
    }
    return [];
  },
  demoPlansList: (state) => {
    return state.portalSettings.demoPlansList;
  },
  portalPlanNumber: (state) => {
    return state.portalSettings.planNumber;
  },
  planSponsorAddress: (state) => {
    return state.portalSettings.planSponsorAddress;
  },
  planBenefits: (state) => {
    return state.portalSettings.planBenefits;
  },
  isDemoMockPlan: (state) => {
    return state.portalSettings.isDemoMockPlan;
  },
  HSAExpiryDate: (state) => {
    return state.portalSettings.HSAExpiryDate;
  },
  isLocalPlan: (state) => {
    return state.portalSettings.isLocalPlan;
  },
  demoBenefitCards: (state) => {
    return state.portalSettings.demoBenefitCards;
  },
  settings: (state) => {
    return state.settings;
  },
  navbarOpen: (state) => {
    return state.navbarOpen;
  },
  leftDrawerOpen: (state) => {
    return state.leftDrawerOpen;
  },
  language: (state) => {
    return state.language;
  },
  languages: (state) => {
    return state.languages;
  },
  vuetifyTheme: (state) => {
    return state.portalSettings.theme.Vuetify;
  },
  rightDrawerTheme: (state) => {
    const mode = state.mode === "light" ? "Light" : "Dark";
    return state.portalSettings.theme.RightDrawer[mode];
  },
  leftDrawerTheme: (state) => {
    const mode = state.mode === "light" ? "Light" : "Dark";
    return state.portalSettings.theme.LeftDrawer[mode];
  },
  appBarTheme: (state) => {
    const mode = state.mode === "light" ? "Light" : "Dark";
    return state.portalSettings.theme.AppBar[mode];
  },
  appFooterTheme: (state) => {
    const mode = state.mode === "light" ? "Light" : "Dark";
    return state.portalSettings.theme.AppFooter[mode];
  },
  mainViewTheme: (state) => {
    const mode = state.mode === "light" ? "Light" : "Dark";
    return state.portalSettings.theme.MainView[mode];
  },
  leftDrawerMenuItems: (state) => {
    return state.portalSettings.leftDrawerMenuItems;
  },
  footerItems: (state) => {
    return state.portalSettings.footerItems;
  },
  studentWellness: (state) => {
    return state.portalSettings.studentWellness;
  },
  lifeEvents: (state) => {
    return state.portalSettings.lifeEventsCCWIPP;
  },
  faq: (state) => {
    return state.portalSettings.faq;
  },
  downloadCentreData: (state) => {
    return state.portalSettings.downloadCentreData;
  },
  dashboardItems: (state) => {
    return state.portalSettings.dashboardItems;
  },
  dashboardItemTypes: (state) => {
    return state.portalSettings.dashboardItemTypes;
  },
  availableDashboardItemTypes: (state) => {
    const alreadyUsed = state.portalSettings.dashboardItems
      ? state.portalSettings.dashboardItems.map((i) => i.Name)
      : [];
    const unused = state.portalSettings.dashboardItemTypes
      ? state.portalSettings.dashboardItemTypes.filter(
          // eslint-disable-next-line prettier/prettier
          (i) => alreadyUsed.indexOf(i.Name) < 0
        )
      : [];
    return unused;
  },
  memberInformationItems: (state) => {
    return state.portalSettings.memberInformationItems;
  },
  contactUsNote: (state) => {
    return state.portalSettings.contactUsNote;
  },
  contactInfo: (state) => {
    return state.portalSettings.contactInfo;
  },
  claimsHistoryNote: (state) => {
    return state.portalSettings.claimsHistoryNote;
  },
  benefitsBalanceNote: (state) => {
    return state.portalSettings.benefitsBalanceNote;
  },
  contactRequestType: (state) => {
    return state.portalSettings.contactRequestType;
  },
  contactSubjectType: (state) => {
    return state.portalSettings.contactSubjectType;
  },
  dependantDefinition: (state) => {
    return state.portalSettings.dependantDefinition;
  },
  providerSearchNote: (state) => {
    return state.portalSettings.providerSearchNote;
  },
  hasProviderSearch: (state) => {
    return state.portalSettings.hasProviderSearch;
  },
  hasPreferredProviders: (state) => {
    return state.portalSettings.hasPreferredProviders;
  },
  hasDrugSearch: (state) => {
    return state.portalSettings.hasDrugSearch;
  },
  hasDemoHSA: (state) => {
    return state.portalSettings.hasDemoHSA;
  },
  hsaLabel: (state) => {
    return state.portalSettings.hsaLabel;
  },
  hideHsaCoverages: (state) => {
    return state.portalSettings.hideHsaCoverages;
  },
  canEditDependants: (state) => {
    return state.portalSettings.canEditDependants;
  },
  hasEligibilityBank: (state) => {
    return state.portalSettings.hasEligibilityBank;
  },
  hoursBankNote: (state) => {
    return state.portalSettings.hoursBankNote;
  },
  hasBeneficiaries: (state) => {
    return state.hasBeneficiariesFlag;
  },
  hasWelcomeTour: (state) => {
    return state.portalSettings.hasWelcomeTour;
  },
  hideFrequentlyUsed: (state) => {
    return state.portalSettings.hideFrequentlyUsed;
  },
  HSAnote: (state) => {
    if (state.language && state.language.language === "fr") {
      return state.portalSettings.HSAnote.Fr;
    }
    return state.portalSettings.HSAnote.En;
  },
  drugSearchNote: (state) => {
    return state.portalSettings.drugSearchNote;
  },
  drugExceptionNote: (state) => {
    return state.portalSettings.drugExceptionNote;
  },
  benefitCardsNote: (state) => {
    return state.portalSettings.benefitCardsNote;
  },
  pensionInformationItems: (state) => {
    return state.portalSettings.pensionInformationItems;
  },
  horizontalMenuItems: (state) => {
    return state.portalSettings.horizontalMenuItems;
  },
  userProfile: (state) => {
    return state.userProfile;
  },
  addressLine: (state) => {
    const addressLine =
      (state.userProfile?.Address?.StreetNumber ?? "") +
      " " +
      (state.userProfile?.Address?.StreetName ?? "") +
      " " +
      (state.userProfile?.Address?.StreetType ?? "") +
      " " +
      (state.userProfile?.Address?.StreetDirection ?? "") +
      (state.userProfile?.Address?.AptUnitSuite
        ? ", " + state.userProfile?.Address?.AptUnitSuite
        : "");
    if (addressLine.trim().length) {
      return addressLine;
    } else
      return (
        state.userProfile?.Address?.AddressLine +
        " " +
        (state.userProfile?.Address?.AddressLine2
          ? state.userProfile?.Address?.AddressLine2 + " "
          : "") +
        (state.userProfile?.Address?.AddressLine3
          ? state.userProfile?.Address?.AddressLine3 + " "
          : "")
      );
  },
  mode: (state) => {
    return state.mode;
  },
  welcomeVideo: (state) => {
    return state.portalSettings.welcomeVideo;
  },
  planAdministrator: (state) => {
    return state.portalSettings.planAdministrator;
  },
  planAdministratorFr: (state) => {
    return state.portalSettings.planAdministratorFr;
  },
  isPensionsPortal: (state) => {
    return state.portalSettings.isPensionsPortal;
  },
  planAdministratorName: (state) => {
    return state.portalSettings.planAdministratorName;
  },
  planAdministratorNameFr: (state) => {
    return state.portalSettings.planAdministratorNameFr;
  },
  logoutUrls: (state) => {
    return state.portalSettings.logoutUrls;
  },
  adminEmailRecipients: (state) => {
    return state.portalSettings.adminEmailRecipients;
  },
  planAdministratorWebsite: (state) => {
    return state.portalSettings.planAdministratorWebsite;
  },
  planAdministratorWebsiteFr: (state) => {
    return state.portalSettings.planAdministratorWebsiteFr;
  },
  logo: (state) => {
    return state.portalSettings.logo;
  },
  logoFr: (state) => {
    return state.portalSettings.logoFr;
  },
  favicon: (state) => {
    return state.portalSettings.favicon;
  },
  benSource: (state) => {
    return state.benSource;
  },
  pageTitle: (state) => {
    return state.pageTitle;
  },
  certificateDigits: (state) => {
    return state.portalSettings.certificateDigits;
  },
  certificateLabel: (state) => {
    const label = state.portalSettings.enumModels?.CertificateLabels?.find(
      (l) => l.value === state.portalSettings?.showCertificateLabelAs
    );
    if (label) {
      return state.language.language === "fr" ? label.textFr : label.textEn;
    } else return null;
  },
  memberGroupLabel: (state) => {
    const label = state.portalSettings.enumModels.MemberGroupLabels.find(
      (l) => l.value === state.portalSettings.showMemberGroupLabelAs
    );
    if (label) {
      return state.language.language === "fr" ? label.textFr : label.textEn;
    } else return null;
  },
  memberValidateTooltip: (state) => {
    return state.language.language === "fr"
      ? state.portalSettings?.memberValidateTooltip?.Fr
      : state.portalSettings?.memberValidateTooltip?.En;
  },
  pageHelpTooltip: (state) => {
    return state.pageHelpTooltip;
  },
  enumModels: (state) => {
    return state.portalSettings.enumModels;
  },
  memberRegistrationComponent: (state) => {
    return state.portalSettings.memberRegistrationComponent;
  },
  planInformation: (state) => {
    return state.portalSettings.planInformation;
  },
  registrationCompletionComponent: (state) => {
    return state.portalSettings.registrationCompletionComponent;
  },
  identityUser: (state) => {
    return state.identityUser;
  },
  optionalMemberFields: (state) => {
    return state.portalSettings.optionalMemberFields;
  },
  canadaPostStreetTypes: (state) => {
    return state.portalSettings.canadaPostStreetTypes;
  },
  canadaPostStreetTypesFR: (state) => {
    return state.portalSettings.canadaPostStreetTypesFR;
  },
  usStreetTypes: (state) => {
    return state.portalSettings.usStreetTypes;
  },
  helpVideos: (state) => {
    return state.portalSettings.helpVideos;
  },
};

const actions2 = {
  setPlan({ commit }, value) {
    commit("setPlan", value);
  },
  setHasOneBenefitCard({ commit }, value) {
    commit("setHasOneBenefitCard", value);
  },
  setMemberPlans({ commit }, value) {
    commit("setMemberPlans", value);
  },
  setLanguage({ commit, state }, value) {
    commit("setLanguage", value);
    const langs = state.languages.map((l) => l.language).join(", ");
    axios.defaults.headers.common["Accept-Language"] = langs;
  },
  setLanguageFromUrl({ commit }, value) {
    if (value === "en")
      commit("setLanguage", { language: "en", title: "English" });
    else if (value === "fr")
      commit("setLanguage", { language: "fr", title: "Français" });
  },
  getLanguage({ commit }) {
    return commit.language;
  },
  setSettings({ commit }, value) {
    commit("setSettings", value);
  },
  setNavbarOpen({ commit }, value) {
    commit("setNavbarOpen", value);
  },
  setLeftDrawerOpen({ commit }, value) {
    commit("setLeftDrawerOpen", value);
  },
  async loadPortalSettings({ state }) {
    try {
      const settings = SettingsService.settings;
      // console.log(
      //   "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",
      //   JSON.stringify(settings),
      //   settings.APIURL
      // );
      let url = `${settings.APIURL}portal-settings/${encodeURIComponent(
        // eslint-disable-next-line prettier/prettier
        window.location.host
      )}`;
      const data = (await ApiService.getPublic(url)).data;
      if (data.Json) {
        state.portalSettings = data.Json;
        const languages = data.Json?.languages;
        if (languages) {
          state.language = {
            language: languages[0] || "en",
            title: languages[0] === "fr" ? "Français" : "English",
          };
          const languagesFromJson = languages.map((lang) => {
            const flag = lang === "fr" ? "fr" : "us";
            const title = lang === "fr" ? "Français" : "English";
            return {
              flag,
              language: lang,
              title,
            };
          });
          state.languages = languagesFromJson;
        }
      } else {
        state.portalSettings = data;
      }

      state.useLegacyClaims = data.UseLegacyClaims;

      if (data.languages) {
        state.language = {
          language: data.languages[0] || "en",
          title: data.languages[0] === "fr" ? "Français" : "English",
        };
        const languagesFromJson = data.languages.map((lang) => {
          const flag = lang === "fr" ? "fr" : "us";
          const title = lang === "fr" ? "Français" : "English";
          return {
            flag,
            language: lang,
            title,
          };
        });
        state.languages = languagesFromJson;
      }
    } catch (error) {
      console.log(error);
    }
  },
  setDashboardItems({ commit }, value) {
    commit("setDashboardItems", value);
  },
  resetDashboardItems({ commit, state }) {
    let defaultDashboardItems = state.portalSettings.dashboardItemTypes.filter(
      // eslint-disable-next-line prettier/prettier
      (item) => item.IsDefault
    );
    defaultDashboardItems.forEach((item, i) => (item.Id = i.toString()));
    defaultDashboardItems.push({
      Id: "5",
      Order: 5,
      Name: "AssistantDashboardItem",
      Size: 4,
    });
    defaultDashboardItems.push({
      Id: "6",
      Order: 6,
      Name: "EmptyDashboardItem",
      Size: 8,
    });

    commit("resetDashboardItems", defaultDashboardItems);
  },
  // async loadUserImage({ state }, options) {
  //   try {
  //     const settings = SettingsService.settings;
  //     let url = `${settings.AUTH}api/authenticate/${options.userId}/GetUser`;
  //     const userProfile = (await ApiService.get(url)).data;
  //     if (userProfile && userProfile.Image) {
  //       if (state.userProfile) {
  //         state.userProfile.Image = userProfile.Image;
  //       }
  //       return userProfile.Image;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  // eslint-disable-next-line no-unused-vars
  async loadIdentityUser({ state }, options) {
    console.log("load identity user");
    try {
      const settings = SettingsService.settings;
      let url = `${settings.AUTH}api/authenticate/${options.userId}/GetUser`;
      const identityUser = (await ApiService.get(url)).data;
      // console.log("store loaded identity user", identityUser);
      state.identityUser = identityUser;
      return identityUser;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getUserByEmail({ state }, email) {
    const settings = SettingsService.settings;
    let url = `${settings.AUTH}api/authenticate/GetUserByEmail/${email}`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line no-unused-vars
  async getProfile({ state }, url) {
    return await ApiService.get(url);
    //return await ApiService.getDeployedProxy(url);
  },
  // async getHasBeneficiariesFlag({ state, rootState }) {
  async getHasBeneficiariesFlag({ state }) {
    // let policies = null;
    // if (!state.portalSettings.hasBeneficiaries) {
    //   state.hasBeneficiariesFlag = false;
    //   return new Promise((resolve) => resolve(state.hasBeneficiariesFlag));
    // }
    // if (!state.hasBeneficiariesFlag) {
    //   if (state.portalSettings.isDemoMockPlan) {
    //     policies = mocksCommon.mockHasBeneficiariesFlag;
    //     console.log("mocksCommon.mockHasBeneficiariesFlag", policies);
    //   } else {
    //     const settings = SettingsService.settings;
    //     const url = `${settings.APIURL}plans/${state.plan}/members/${rootState?.auth?.user?.drawbridgeMemberId}/policy-identities`;
    //     policies = await ApiService.get(url);
    //   }
    //   console.log("policies", policies);
    //   // state.hasBeneficiariesFlag = policies?.data && policies.data.length > 0;
    //   state.hasBeneficiariesFlag = true;
    // }
    state.hasBeneficiariesFlag = state.portalSettings.hasBeneficiaries;
    return new Promise((resolve) => resolve(state.hasBeneficiariesFlag));
  },
  parseCookie(str) {
    if (!str) return;
    let cookie = str
      .split(";")
      .find((c) => c.trim().startsWith(".AspNetCore.Culture="));
    let value =
      cookie.split(".AspNetCore.Culture=")?.length > 1
        ? cookie.split(".AspNetCore.Culture=")[1]
        : "";
    if (!value) return "";
    return decodeURIComponent(value)
      .split("|")
      .map((v) => v.split("="))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(
          v[1]?.trim()
        );
        return acc;
      }, {});
  },
  async loadUserProfileData({ state, dispatch, commit }, options) {
    console.log("load user profile");
    let parsedCookie = this.parseCookie(document.cookie);
    console.log("parsed cookie", parsedCookie);
    if (parsedCookie) {
      if (
        (parsedCookie?.c === "fr" || parsedCookie?.uic === "fr") &&
        state.language?.language !== "fr"
      ) {
        commit("setLanguage", { language: "fr", title: "Français" });
        console.log("parsed cookie set", parsedCookie, state.language.language);
      } else {
        if (
          (parsedCookie?.c === "en" || parsedCookie?.uic === "en") &&
          state.language?.language !== "en"
        ) {
          commit("setLanguage", { language: "en", title: "English" });
          console.log(
            "parsed cookie set",
            parsedCookie,
            state.language.language
          );
        }
      }
    }

    const settings = SettingsService.settings;
    let url = `${settings.APIURL}plans/${options.planId}/members/${options.userId}/profile`;
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planId}/members/${options.userId}/profile`;
    let userProfile = {};
    try {
      let data = await ApiService.get(url);
      // let data = await ApiService.getDeployedProxy(url);
      //let data = await dispatch("getProfile", url);
      userProfile = data.data;
      //userProfile = (await ApiService.getDeployedProxy(url)).data;
      // console.log(
      //   "common store: user profile loaded 1",
      //   url,
      //   userProfile,
      //   data
      // );
    } catch (error) {
      console.log(error);
    }
    try {
      if (
        state.portalSettings.isDemoMockPlan ||
        state.portalSettings.isLocalPlan
      ) {
        console.log("this is demo", state.portalSettings.isDemoMockPlan);
        if (!userProfile?.FirstName) {
          userProfile = mocksCommon.getProfile.data;
          if (state.portalSettings.isPensionsPortal) {
            console.log(
              "this is pensions portal, using pensions profile",
              userProfile.AccountStatus
            );
            userProfile = mocksCommon.getProfilePensions.data;
          }
        }
      } //else {
      // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planId}/members/${options.userId}/profile`;
      // userProfile = (await ApiService.getDeployedProxy(url)).data;
      //   userProfile = await ApiService.get(url).data;
      //   console.log("common store: user profile loaded 1", userProfile);
      // }
      // userProfile.Image = await dispatch("loadUserImage", options);
      await dispatch("loadIdentityUser", options);
      userProfile.Image = state.identityUser.Image;
      userProfile.MemberPlans = state.identityUser.PlanMemberships;
      userProfile.Dependants = userProfile?.Dependants?.sort((a, b) => {
        if (Date.parse(a.DateOfBirth) < Date.parse(b.DateOfBirth)) {
          return -1;
        } else if (Date.parse(a.DateOfBirth) === Date.parse(b.DateOfBirth)) {
          return 0;
        } else {
          return 1;
        }
      });
      userProfile.EmailAddress = state.identityUser.Email;
      if (
        state.portalSettings.isDemoMockPlan &&
        state.portalSettings.demoPlansList &&
        state.portalSettings.demoPlansList.length > 0
      ) {
        console.log(
          "setting demo plans list",
          state.portalSettings.demoPlansList
        );
        userProfile.MemberPlans = state.portalSettings.demoPlansList;
      }
      state.userProfile = userProfile;
      // console.log("common store: user profile loaded 2", userProfile);
    } catch (error) {
      console.log(error);
    }
  },
  clearUserProfileData({ state }) {
    state.userProfile = undefined;
  },
  getLifeEventsData({ state }) {
    // return { data: state.portalSettings.lifeEvents };
    return { data: state.portalSettings.lifeEventsCCWIPP };
  },
  getPreferredProvidersData({ state }) {
    const preferredProviders = state.portalSettings.preferredProviders;
    if (state.portalSettings.isDemoMockPlan && !preferredProviders?.length) {
      console.log(
        "common.store: Returning demo preferred providers",
        demoPreferredProviders
      );
      return { data: demoPreferredProviders || [] };
    }
    return { data: preferredProviders };
  },
  getFaqData({ state }) {
    return { data: state.portalSettings.faq };
  },
  getDownloadCentreData({ state }) {
    return { data: state.portalSettings.downloadCentreData };
  },
  setBenSource({ state }, value) {
    state.benSource = value;
  },
  setUserProfileImage({ state }, value) {
    state.userProfile.Image = value;
  },
  setPageTitle({ state }, value) {
    state.pageTitle = value;
  },
  setPageHelpTooltip({ state }, value) {
    state.pageHelpTooltip = value;
  },
};

const mutations = {
  setPlan(state, value) {
    localStorage.selectedPlan = JSON.stringify(value);
    state.plan = value;
  },
  setHasOneBenefitCard(state, value) {
    state.hasOneBenefitCard = value;
  },
  setMemberPlans(state, value) {
    state.userProfile.MemberPlans = value;
  },
  setSettings(state, value) {
    state.settings = value;
  },
  setNavbarOpen(state, value) {
    state.navbarOpen = value;
  },
  setLeftDrawerOpen(state, value) {
    state.leftDrawerOpen = value;
  },
  setLanguage(state, value) {
    let langToSet = value;
    if (state.languages?.length === 1) {
      langToSet = state.languages[0];
    }
    const administratorName =
      langToSet.language === "fr"
        ? state.portalSettings.planAdministratorFr
          ? state.portalSettings.planAdministratorFr
          : state.portalSettings.planAdministrator
        : state.portalSettings.planAdministrator;
    i18n.locale = langToSet.language;
    document.title =
      (administratorName ? administratorName + "-" : "") +
      i18n.t("globals.app_title");
    document.cookie = `.AspNetCore.Culture=c=${langToSet.language}|uic=${langToSet.language}`;
    state.language = langToSet;
    window.localStorage[`language`] = JSON.stringify(langToSet);
    // document.title = i18n.t("globals.app_title");
  },
  setDashboardItems(state, value) {
    state.portalSettings.dashboardItems = value;
  },
  resetDashboardItems(state, value) {
    state.portalSettings.dashboardItems = value;
  },
};

const mocks = mocksCommon;

// For Demo portals return mock data
const actions = {};
Object.keys(actions2).forEach((k) => {
  actions[k] = (s, options) => {
    // console.log(s, options);
    if (state.portalSettings.isDemoMockPlan) {
      // console.log("common.store: This is the Demo Mock Plan");
      if (mocks[k]) return mocks[k];
    }
    // console.log(actions2[k]);
    return actions2[k](s, options);
  };
});

export const common = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
